/**
 * Helper function to determine which translation key to use for special notes
 * @param {string} notes - The special notes text
 * @returns {string|null} - The translation key or null if no notes
 */
export const getSpecialNotesTranslationKey = (notes) => {
  if (!notes) return null;
  
  return notes === 'All services available except PLC meeting minutes' 
    ? "specialText"
    : "specialTextTwo";
}; 