import React, { useState } from "react";
import { DayPicker } from 'react-day-picker';
import { addDays, subMonths, addMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';
import { convertToEthiopianDate, formatTime } from '../../utils/convertTime.js';
import { getSpecialNotesTranslationKey } from '../../utils/specialNotesHelper';
import { FaBuildingColumns } from "react-icons/fa6";

const DateSelection = ({ selectedDate, setSelectedDate, selectedBranchName, selectedServiceName, specialNotes, nextStep, backStep }) => {
  const { t } = useTranslation();
  const [tempDate, setTempDate] = useState(null); // Temporarily selected date
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false); // Confirm modal state
  const today = new Date();
  const tenDaysLater = addDays(today, 6); 

  const handleDateClick = (date) => {
    setTempDate(date);
    setConfirmModalOpen(true); // Open confirm modal
  };

  const handleConfirm = () => {
    setSelectedDate(tempDate ? tempDate : selectedDate); // Set the confirmed date
    setConfirmModalOpen(false); // Close the modal
    nextStep(); // Proceed to the next step
  };

  const handleCancel = () => {
    setTempDate(null); // Clear the temporary date
    setConfirmModalOpen(false); // Close the modal
  };

  // Define the limits for month navigation
  const fromMonth = subMonths(today, 0);
  const toMonth = addMonths(today, 1);

  return (
    <div className="max-w-md mx-auto bg-gray-50 p-5 rounded-3xl shadow-sm">
      {/* Branch and Service Info Card - Enhanced with gradient background */}
      <div className="flex items-center p-5 mt-2 bg-gradient-to-r from-white to-blue-50 border-gray-200 border rounded-2xl shadow-md hover:shadow-lg transition-all duration-300">
        <div className="flex items-center justify-center bg-blue-100 p-3.5 rounded-xl mr-4 shadow-sm">
          <FaBuildingColumns className="w-8 h-8 text-blue-700" />
        </div>
        <div className="flex flex-col">
          <h2 className="font-bold text-lg text-gray-800 mb-1">
            {t(selectedBranchName)} {t("branch")}
          </h2>
          <p className="text-sm text-gray-600">
            {t("service")}: <span className="font-medium text-blue-700">{t(selectedServiceName)}</span>
          </p>
        </div>
      </div>

      {/* Special Notes Section - Moved outside the branch info card */}
      {specialNotes && (
        <div className="bg-blue-50 border border-blue-100 rounded-xl p-4 mt-4 shadow-sm">
          <p className="text-sm text-blue-700 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 flex-shrink-0 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2h-1V9a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            {t(getSpecialNotesTranslationKey(specialNotes))}
          </p>
        </div>
      )}

      {/* Calendar Container - Enhanced with better spacing and styling */}
      <div className="bg-white border p-5 my-5 rounded-2xl border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
        <h2 className="text-lg font-bold text-gray-800 mb-4 border-b pb-3 border-gray-100 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
          </svg>
          {t("selectDate")}
        </h2>

        <div className="flex justify-center">
          <DayPicker
            mode="single"
            selected={tempDate || selectedDate}
            onSelect={handleDateClick}
            startMonth={fromMonth}
            endMonth={toMonth}
            disabled={[
              { before: today }, 
              { after: tenDaysLater }, 
              { dayOfWeek: [0] }, 
            ]}
            modifiersClassNames={{
              selected: 'bg-blue-600 text-white rounded-full font-bold hover:bg-blue-700',
              default: "bg-white rounded-full",
              today: 'bg-blue-100 text-blue-800 font-semibold rounded-full',
              disabled: 'text-gray-300 cursor-not-allowed',
              future: "text-gray-500 cursor-not-allowed",
            }}
            className="mx-auto border border-gray-100 p-3 text-gray-800 text-sm rounded-xl shadow-sm transition-all duration-300 ease-in-out"
            styles={{
              caption: { color: '#3B82F6' },
              head_cell: { color: '#4B5563', fontWeight: 600 },
              day: { margin: '0.2em', borderRadius: '9999px' }
            }}
          />
        </div>
      </div>

      {/* Confirmation Modal - Enhanced with better styling */}
      {isConfirmModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="bg-white rounded-2xl shadow-2xl p-6 w-11/12 max-w-sm border border-gray-100 transform transition-all duration-300 ease-in-out">
            <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-3 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              {t("confirmDate")}
            </h2>
            <div className="bg-blue-50 rounded-xl p-4 mb-4">
              <p className="text-gray-700 mb-2 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
                {t('selectedDate')}: <span className="font-semibold ml-1">{tempDate ? formatTime(tempDate) : formatTime(selectedDate)}</span>
              </p>
              <p className="text-gray-700 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                </svg>
                {t('ethiopianDate')}: <span className="font-semibold ml-1">{tempDate ? convertToEthiopianDate(tempDate) : formatTime(selectedDate)}</span>
              </p>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-5 py-2.5 rounded-lg border border-gray-300 text-gray-700 font-medium hover:bg-gray-100 transition duration-300 ease-in-out flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                {t('cancel')}
              </button>
              <button
                onClick={handleConfirm}
                className="bg-blue-600 text-white px-5 py-2.5 rounded-lg shadow-md hover:bg-blue-500 transition duration-300 ease-in-out font-medium flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                {t('next')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Back Button - Enhanced with better styling */}
      <div className="flex w-full justify-between mt-4 mb-2 px-2">
        <button
          onClick={backStep}
          className="flex items-center px-5 py-2.5 rounded-lg border border-gray-300 bg-white text-gray-700 font-medium hover:bg-gray-100 transition duration-300 ease-in-out shadow-sm"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
          {t('back')}
        </button>
      </div>
    </div>
  );
};

export default DateSelection;
