import React, { useState } from 'react';
import i18n from '../../i18n';
import { convertToAmPm, convertToEthiopianDate, convertToEthiopianLocalTime, formatTime } from '../../utils/convertTime';
import { useMutation } from '@tanstack/react-query'; // Import useMutation
import axios from 'axios';

const ConfirmationModal = ({
    selectedBranchName,
    selectedServiceName,
    isLoading,
    selectedDate,
    selectedTimeSlot,
    totalFee = "50 ETB",
    onClose,
    documentCount,
    setDocumentCount,
    onConfirm,
    firstname,
    lastname
  }) => {
    const [errorMessage, setErrorMessage] = useState("");

    const handleDocumentCountChange = (e) => {
      const value = parseInt(e.target.value, 10);
  
      if (value > 3) {
        setErrorMessage(i18n.t("For 1 appointment, you can have up to 3 documents."));
        setDocumentCount(3);
      } else {
        setErrorMessage("");
        setDocumentCount(value);
      }
    };
  

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-2 z-50">
        <div className="bg-white border border-gray-400 p-6 md:p-12 rounded-lg shadow-2xl w-full max-w-md md:max-w-lg transform transition-all duration-300">
          <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-8 text-start">{i18n.t('confirm_booking')}</h2>
          <div>
            </div>
          <div className="text-sm md:text-lg text-gray-700">
      {  firstname   && (<div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('apointment_for')}:</span>
              <span className="font-semibold">{firstname} {lastname}</span>
            </div>)}


            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('branch')}:</span>
              <span className="font-semibold">{i18n.t(selectedBranchName)}</span>
            </div>
            <div className="flex justify-between gap-4 pb-4 mb-4 border-b border-gray-300">
              <span className="font-normal">{i18n.t('service')}:</span>
              <span className="font-semibold">{i18n.t(selectedServiceName)}</span>
            </div>
  
            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('bookingDate')}:</span>
              <div className="flex flex-col justify-end items-end">
                <span className="font-semibold text-xs md:text-sm">{convertToEthiopianDate(selectedDate)} </span>
                <span className="font-semibold  text-xs md:text-sm">{formatTime(selectedDate)} </span>
              </div>
            </div>
            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('bookingTime')}</span>
              <div className="flex flex-col justify-end items-end">
                <span className="font-semibold text-xs md:text-sm">{convertToEthiopianLocalTime(selectedTimeSlot?.time_from)}{`( ${convertToAmPm(selectedTimeSlot?.time_from)} )`}</span>
              </div>
            </div>
          </div>
  
          <div className="mt-8 flex justify-between align-middle bg-blue-100 p-4 rounded-lg text-lg md:text-xl pt-2">
            <div className="flex flex-col">
              <p className="text-sm font-normal text-gray-500 ">{i18n.t('payment_summary')}</p>
              <span className="font-medium text-gray-600">{i18n.t('serviceFee')}:</span>
            </div>
            <span className="text-green-600 self-end font-black">{totalFee}</span>
          </div>
          
          <div className="mt-4">
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">{i18n.t('Number of documents')}</label>
              <input
                type="number"
                min="1"
                max="3"
                value={documentCount}
                onChange={handleDocumentCountChange}
                className="block w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:ring-primary focus:border-primary"
                required
              />
              {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
            </div>
          </div>
  
          <div className="flex flex-col align-middle text-base justify-start">
            <img src={'./TeleBirr-Logo.svg'} alt="Telebirr Logo" className="w-24 h-16 contain-content self-center" />
            <h3 className="text-center text-lg text-gray-800 font-semibold">{i18n.t("pay_with_telebirr")}</h3>
          </div>
  
          <div className="flex justify-around items-center space-x-4 mt-4">
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 text-base md:text-lg py-2 px-5 rounded-lg shadow hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none transition duration-300"
            >
              {i18n.t('cancel')}
            </button>
            <button
              onClick={onConfirm}
              className="flex items-center bg-primary font-bold border border-primary text-white text-sm md:text-lg py-2 px-5 rounded-lg shadow hover:bg-black-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-300"
            >
              {isLoading ? i18n.t('loading') : i18n.t('confirmAndPay')}
            </button>
          </div>
        </div>
      </div>
    );
  };


export default ConfirmationModal;